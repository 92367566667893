<template>
  <div>
    <el-dialog
      title="Attachment"
      :visible.sync="dialogVisible"
      top="12vh"
      width="800px"
      :close-on-click-modal="false"
      @close="closeHandler">
      <div class="dialog-container">
        <div v-if="attachments.length > 0" class="attachments-container">
          <div
            class="attachment-item"
            v-for="item in attachments"
            :key="item.id">
            <span class="attachment-name">{{item.originalName || item.name}}</span>
            <div class="button-group">
              <el-button
                round
                plain
                size="small"
                type="primary"
                @click="openPreview(item)"
              >View</el-button>
              <el-popconfirm
                :title="$t('deleteTip')"
                style="margin-left: 8px;"
                @confirm="removeHandler(item)"
              >
                <el-button
                  slot="reference"
                  round
                  plain
                  size="small"
                  type="danger"
                >Delete</el-button>
              </el-popconfirm>
            </div>
          </div>
        </div>
        <div class="no-data" v-else>
          <img src="@/assets/images/no_data.png" alt="">
          <span>{{ $t('noDataAlt') }}</span>
        </div>
      </div>
      <span class="dialog-footer">
        <el-upload
          multiple
          accept=".pdf,.jpg,.doc,.docx,.jpeg"
          :show-file-list="false"
          :action="uploadUrl"
          :file-list="fileList"
          :on-success="successHandler"
          :on-change="changeHandler"
          :before-remove="removeHandler">
          <el-button
            type="primary"

            round>
            Upload Order Files
          </el-button>
          <div class="el-upload__tip">
            Tip: Only Pdf/Jpeg/Word files can be uploaded
          </div>
        </el-upload>
      </span>
    </el-dialog>
    <file-preview :visible.sync="previewVisible" :file="currentFile"></file-preview>
  </div>
</template>

<script>
import FilePreview from '@/components/FilePreview'

export default {
  components: { FilePreview },
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    order: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      uploadFieldName: '',
      dialogVisible: this.visible,
      previewVisible: false,
      currentFile: null,
      fileList: [],
      deleteFileIds: []
    }
  },
  computed: {
    uploadUrl () {
      return this.$apis.baseUrl + this.$apis.uploadFile
    },
    contractType () {
      return this.order.tradeType?.toLowerCase()
    },
    attachments () {
      const mapping = {
        purchase: 'purchaseOrders',
        sales: 'salesOrders'
      }
      const purchaseFiles = this.order[mapping.purchase] || []
      const salesFiles = this.order[mapping.sales] || []
      const oldFiles = [...purchaseFiles, ...salesFiles, ...this.fileList]
      return oldFiles.filter(item => !this.deleteFileIds.includes(item.id))
    }
  },
  watch: {
    visible () {
      this.dialogVisible = this.visible
      this.fileList = []
    }
  },
  methods: {
    openPreview (file) {
      this.currentFile = file
      this.previewVisible = true
    },

    /**
     * 上传文件成功后保存到订单
     * @param {Object} response:响应数据
     * @param {Object} file:文件
     */
    successHandler (response, file) {
      const { id } = response.data
      file.id = id
      this.save(file)
    },

    changeHandler (file, fileList) {
      this.fileList = fileList
    },

    /**
     * 保存到订单没有成功则删除已经上传的文件
     * @param {Object} file:文件
     */
    removeFile (file) {
      this.fileList = this.fileList.filter(item => item.id !== file.id)
    },

    /**
     * 监听手动删除文件，同时删除订单中的关联
     * @param {Object} file:文件
     */
    removeHandler (file) {
      return new Promise((resolve, reject) => {
        this.$request.post({
          url: this.$apis.deleteFile,
          data: [file.id]
        }).then(res => {
          if (res?.code === 1000) {
            this.deleteFileIds.push(file.id)
            return resolve()
          }
          return reject(new Error())
        }).catch(e => {
          return reject(new Error())
        })
      })
    },
    // 保存附件
    save (file) {
      const mapping = {
        purchase: 'purchaseOrderIds',
        sales: 'salesOrderIds'
      }
      const data = {
        id: this.order.id
      }
      data[mapping[this.contractType]] = [file.id]
      this.$request.post({
        url: this.$apis.saveContract,
        data
      }).then(res => {
        if (res?.code === 1000) {
          this.$message.success(`'${file.name}' uploaded successfully!`)
        } else {
          this.removeFile(file)
        }
      }).catch(e => {
        this.removeFile(file)
      })
    },
    // 弹窗关闭回调
    closeHandler () {
      let purchaseOrders = this.order.purchaseOrders || []
      let salesOrders = this.order.salesOrders || []
      if (this.contractType === 'purchase') {
        purchaseOrders = [...purchaseOrders, ...this.fileList]
      } else {
        salesOrders = [...salesOrders, ...this.fileList]
      }
      this.$emit('close', { purchaseOrders, salesOrders })
      this.$emit('update:visible', false)
    }
  },
  mounted () {
  }

}

</script>

<style lang='scss' scoped>
/deep/ .el-dialog {
  max-width: 1976px;

  .el-dialog__title {
    font-weight: 700;
    font-size: 20px;
  }
}
.dialog-container {
  // @include flex-center-column;
  min-height: 45vh;
  max-height: 63vh;
  overflow-y: auto;
}
.attachments-container {
  width: 100%;
  .attachment-item {
    border-bottom: 1px solid #eee;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .attachment-name {
      font-size: 15px;
    }
  }
}
.no-data {
  @include flex-center-column;
  margin: auto;
  padding-bottom: 10vh;

  img {
    width: 200px;
    height: 200px;
  }

  span {
    font-size: 16px;
    color: #C0C4CC;
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
  padding: 5px;
  /deep/ .el-button {
    padding: 12px 45px;
  }
  .el-upload__tip {
    color: #909399;
  }
}
</style>
